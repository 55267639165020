import VeeValidate, { Validator } from 'vee-validate'
import PhoneNumber from 'awesome-phonenumber'
import Vue from 'vue'
import { required } from 'vee-validate/dist/rules.esm'

Validator.extend('phone', {
  getMessage: (field) => `${field} is not a valid phone number`,
  validate(value) {
    return new Promise((resolve) => {
      const phone = new PhoneNumber(value)
      resolve({ valid: phone.isValid() })
    })
  }
})

Validator.extend('dayFromValidate', {
  paramNames: ['today', 'dayFrom', 'dayTo'],
  validate: (value, { today, dayFrom, dayTo }) => {
    const dayFromDate = new Date(dayFrom).setHours(0, 0, 0, 0)
    const dayToDate = new Date(dayTo).setHours(0, 0, 0, 0)

    return dayFromDate >= today && dayToDate >= dayFromDate
  },
  getMessage: (field, params, data) => '"Data od" musi być wieksza lub równa od bieżącej daty i mniejsza niż "Data do"'
})

Validator.extend('dayToValidate', {
  paramNames: ['today', 'dayFrom', 'dayTo'],
  validate: (value, { today, dayFrom, dayTo }) => {
    const dayFromDate = new Date(dayFrom).setHours(0, 0, 0, 0)
    const dayToDate = new Date(dayTo).setHours(0, 0, 0, 0)

    return dayToDate >= today && dayToDate >= dayFromDate
  },
  getMessage: (field, params, data) => '"Data do" musi być wieksza lub równa od bieżącej daty i większa niż "Data od"'
})

Validator.extend('requiredIfActive', {
  paramNames: ['active'],
  validate: (value, { active }) => {
    console.log('requiredIfActive: ' + value + ' ; ' + active)
    return !active || value
  },
  getMessage(field, params, data) {
    const fields = {
      dayFrom: 'Data od',
      dayTo: 'Data do',
      description: 'Nazwa',
      'hourFrom-0': 'Godzina od (PO)',
      'hourFrom-1': 'Godzina od (WT)',
      'hourFrom-2': 'Godzina od (ŚR)',
      'hourFrom-3': 'Godzina od (CZ)',
      'hourFrom-4': 'Godzina od (PT)',
      'hourFrom-5': 'Godzina od (SO)',
      'hourFrom-6': 'Godzina od (NI)',
      'hourTo-0': 'Godzina do (PO)',
      'hourTo-1': 'Godzina do (WT)',
      'hourTo-2': 'Godzina do (ŚR)',
      'hourTo-3': 'Godzina do (CZ)',
      'hourTo-4': 'Godzina do (PT)',
      'hourTo-5': 'Godzina do (SO)',
      'hourTo-6': 'Godzina do (NI)'
    }

    return `Pole "${fields[field]}" jest wymagane`
  }
})

Validator.extend('required', {
  ...required,
  getMessage(field, params, data) {
    const fields = {
      dayFrom: 'Data od',
      dayTo: 'Data do',
      description: 'Nazwa',
      'hourFrom-0': 'Godzina od (PO)',
      'hourFrom-1': 'Godzina od (WT)',
      'hourFrom-2': 'Godzina od (ŚR)',
      'hourFrom-3': 'Godzina od (CZ)',
      'hourFrom-4': 'Godzina od (PT)',
      'hourFrom-5': 'Godzina od (SO)',
      'hourFrom-6': 'Godzina od (NI)',
      'hourTo-0': 'Godzina do (PO)',
      'hourTo-1': 'Godzina do (WT)',
      'hourTo-2': 'Godzina do (ŚR)',
      'hourTo-3': 'Godzina do (CZ)',
      'hourTo-4': 'Godzina do (PT)',
      'hourTo-5': 'Godzina do (SO)',
      'hourTo-6': 'Godzina do (NI)'
    }

    return `Pole "${fields[field]}" jest wymagane`
  }
})

Validator.extend('hourFromValidate', {
  paramNames: ['index', 'to'],
  validate: (value, { index, to }) => {
    const hourFrom = parseInt((value || '0:00').replace(':', ''), 10)
    const hourTo = parseInt((to || '23:59').replace(':', ''), 10)

    return hourFrom <= hourTo
  },
  getMessage: (field, params, data) => {
    const fields = {
      'hourFrom-0': '(PO)',
      'hourFrom-1': '(WT)',
      'hourFrom-2': '(ŚR)',
      'hourFrom-3': '(CZ)',
      'hourFrom-4': '(PT)',
      'hourFrom-5': '(SO)',
      'hourFrom-6': '(NI)'
    }

    return `"Godzina do ${fields[field]}" musi być większa niż "Godzina od ${fields[field]}"`
  }
})

Validator.extend('hourToValidate', {
  paramNames: ['index', 'from'],
  validate: (value, { index, from }) => {
    const hourFrom = parseInt((from || '0:00').replace(':', ''), 10)
    const hourTo = parseInt((value || '23:59').replace(':', ''), 10)

    return hourFrom <= hourTo
  },
  getMessage: (field, params, data) => {
    const fields = {
      'hourTo-0': '(PO)',
      'hourTo-1': '(WT)',
      'hourTo-2': '(ŚR)',
      'hourTo-3': '(CZ)',
      'hourTo-4': '(PT)',
      'hourTo-5': '(SO)',
      'hourTo-6': '(NI)'
    }

    return `"Godzina do ${fields[field]}" musi być większa niż "Godzina od ${fields[field]}"`
  }
})

/*
const config = {
  aria: true,
  classNames: {},
  classes: false,
  delay: 300,
  dictionary: null,
  errorBagName: 'errors',
  events: 'input',
  fieldsBagName: 'fields',
  i18n: null,
  i18nRootKey: 'validations',
  inject: true,
  locale: 'pl',
  validity: false,
  useConstraintAttrs: true
}*/

Vue.use(VeeValidate)
